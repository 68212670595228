<template>
	<div>
		<div class="box fcs">
			<img src="../assets/image/logobg.png" alt="" style="height: 100vh;" mode="heightFix">
			<div class="login">
				<div class="tabbox fcc">
					<!-- fcs -->
					<div :class="current == 0?'tab-a fcc':'tab fcc'" @click="current = 0">登录到奏信后台</div>
					<!-- <div :class="current == 1?'tab-a fcc':'tab fcc'" @click="current = 1">注册</div> -->
				</div>
				<div v-if="current == 0">
					<div style="margin-bottom: 24px;	display: flex;align-items: center;	height: 54px;	background: #F5F5F5; padding:0 10px">
						<img style="height: 24px;width: 20px;" src="../assets/image/phone.png" alt="" sizes="" srcset="">
						<input class="input" placeholder="请输入账号" v-model="loginform.account"></input>
					</div>
					<div style="margin-bottom: 24px;	display: flex;align-items: center;	height: 54px;	background: #F5F5F5; padding:0 10px">
						<img style="height: 24px;width: 20px;" src="../assets/image/passd.png" alt="" sizes="" srcset="">
						<input class="input" placeholder="请输入密码" v-model="loginform.password" type="password"></input>
					</div>
					
					<div class="btn fcc" @click="loginbtn">登录</div>
					<div class="bom fcs">
					
						<!-- @click="checked = !checked" -->
						<div class="fc" style="cursor: pointer;" 	@click="current = 1">
							<!-- <img src="../assets/image/gx.png" alt=""
								style="width: 16px;height: 16px;margin-right: 7px;margin-top: 4px;" v-show="!checked">
							<img src="../assets/image/gx-a.png" alt=""
								style="width: 16px;height: 16px;margin-right: 7px;margin-top: 4px;" v-show="checked"> -->
							<div class="fc">还没有账号?点击 <div style="color: #1372FF;">注册</div>  </div>
						</div>
						<!-- <div style="color: #006DFF;cursor: pointer;">忘记密码？</div> -->
					</div>
				</div>
				<div v-if="current == 1">
					<input class="input2" placeholder="请输入账号" v-model="registerform.username"></input>
					<div style="margin-bottom: 24px;	display: flex;align-items: center;	height: 54px;	background: #F5F5F5; padding:0 10px">
						<img style="height: 24px;width: 20px;" src="../assets/image/passd.png" alt="" sizes="" srcset="">
						<input class="input" placeholder="请输入密码" v-model="registerform.password" type="password"></input>
					</div>
				<div style="margin-bottom: 24px;	display: flex;align-items: center;	height: 54px;	background: #F5F5F5; padding:0 10px">
						<img style="height: 24px;width: 20px;" src="../assets/image/phone.png" alt="" sizes="" srcset="">
						<input class="input" placeholder="请输入手机号" v-model="registerform.mobile"></input>
					</div>
					
					<!-- <input class="input2" placeholder="请输入密码" v-model="registerform.password" type="password"></input>
					<input class="input2" placeholder="请输入手机号" v-model="registerform.mobile" ></input> -->
					<div class="fc">
						<input class="input2" placeholder="请输入验证码" v-model="registerform.code" type="input"
							style="width: 220px;"></input>
						<div class="btns" v-if="!disabledCode" @click="obtain">{{codeText}}</div>
						<div class="btns" v-else style="color: #CCC8C8;">{{codeText}}</div>
					</div>
					<div class="btn2 fcc" @click="registerbtn">注册</div>
					<div class="fc" style="margin-top: 10px;" >
						<img @click="checked = !checked" src="../assets/image/gx.png" alt=""
								style="width: 14px;height: 14px;margin-right: 7px;margin-top: 4px;" v-show="!checked">
							<img @click="checked = !checked" src="../assets/image/gx-a.png" alt=""
								style="width: 14px;height: 14px;margin-right: 7px;margin-top: 4px;" v-show="checked">
								<div class="fc" style="font-size: 14px;">我已阅读并同意  <div style="font-size: 12px;"> <a href="https://www.zx3p.com/index/index/service.html " style="text-decoration: none;cursor: pointer;color: #1372FF;">《平台服务协议》</a> </div> <div style="font-size: 12px;color: #1372FF;"><a href="https://www.zx3p.com/index/index/privacy.html " style="cursor: pointer;text-decoration: none;color: #1372FF;">《平台隐私政策》</a> </div></div>
					</div>
					
					<div @click="current = 0" class="fcc" style="margin-top: 20px;">已有账号? 返回<div style="color: #1372FF;cursor: pointer;	">登录</div>  </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				current: 0,
				registerform: {
					username: '',
					password: '',
					mobile: '',
					code: ''
				},
				loginform: {
					account: '',
					password: ''
				},
				checked: false,
				info: {},
				disabledCode: false,
				codeText: '获取验证码',
				token:""
			}
		},
		created() {

		},
		mounted() {
		if(sessionStorage.getItem('loginform')){
			this.loginform.account=	JSON.parse(sessionStorage.getItem('loginform')).account
			this.loginform.password=	JSON.parse(sessionStorage.getItem('loginform')).password
		}
		
			
		},
		methods: {
			//获取验证码
			obtain() {
				let that = this;
				if (that.registerform.mobile == '') {
					alert('请先输入手机号')
					return;
				}
				let data = {
					mobile: that.registerform.mobile
				}
				this.$api.home.send(data).then(res => {
					if (res.code == 1) {
						that.disabledCode = true;
						this.$message({
							message: '发送成功',
							type: 'success'
						});
						var time = 60;
						var timer = setInterval(fun, 1000);

						function fun() {
							time--;
							if (time >= 0) {
								that.codeText = time + "秒重新发送";
							} else if (time < 0) {
								that.codeText = "获取验证码";
								that.disabledCode = false;
								clearInterval(timer);
								time = 60;
							}
						}
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						});
					}
				})
			},
			//登录
			loginbtn() {
				if (this.loginform.account == '') {
					this.$message({
						message: '账号不能为空',
						type: 'warning'
					});
					return;
				}
				if (this.loginform.password == '') {
					this.$message({
						message: '密码不能为空',
						type: 'warning'
					});
					return;
				}
				this.$api.home.login(this.loginform).then(res => {
					if (res.code == 1) {
						
						sessionStorage.setItem('loginform', JSON.stringify(this.loginform));
						
					
						sessionStorage.setItem('user_info', JSON.stringify(res.data.userinfo));
						sessionStorage.setItem('token', res.data.userinfo.token);
						this.$message({
							message: '恭喜你，登录成功',
							type: 'success'
						});

						this.token= `https://zxadmin.zx3p.com/?Watercup=${this.$Base64.encode(res.data.userinfo.token)}&cid=${this.$Base64.encode('1323215444')}&pid=${this.$Base64.encode('66699888452')}`
						 window.location.href = this.token
						// setTimeout(() => {
						// 	this.$router.push({
						// 		path: '/'
						// 	})
						// 	sessionStorage.setItem('pagepath', '/')
						// }, 1000)
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						});
					}
				})
			},
			//注册
			registerbtn() {
				if(!this.checked){
					this.$message({
						message: '请勾选 《平台服务协议》《平台隐私政策》',
						type: 'warning'
					});
					return;
				}
				if (this.registerform.username == '') {
					this.$message({
						message: '账号不能为空',
						type: 'warning'
					});
					return;
				}
				if (this.registerform.password == '') {
					this.$message({
						message: '密码不能为空',
						type: 'warning'
					});
					return;
				}
				if (this.registerform.mobile == '') {
					this.$message({
						message: '手机号不能为空',
						type: 'warning'
					});
					return;
				}
				if (this.registerform.code == '') {
					this.$message({
						message: '验证码不能为空',
						type: 'warning'
					});
					return;
				}
				this.$api.home.register(this.registerform).then(res => {
					if (res.code == 1) {
						this.$message({
							message: '恭喜你，注册成功',
							type: 'success'
						});
						setTimeout(() => {
							this.registerform.username = '';
							this.registerform.password = '';
							this.registerform.mobile = '';
							this.registerform.code = '';
							this.current = 0;
						}, 1500)
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						});
					}
				})
			}
		}
	}
</script>

<style scoped>
	.box {
		width: 100%;
		height: 100vh;
		box-sizing: border-box;
		background: #F0F5FF;
		padding-right: 20%;
	
	
	}

	.right {
		width: 600px;
		height: 720px;
		background: #FFFFFF;
		box-shadow: 0px 0px 39px 1px rgba(130, 184, 255, 0.1);
	}

	.login {
		width: 430px;
		height: 560px;
		background: #FFFFFF;
		box-shadow: 0px 0px 39px 1px rgba(130, 184, 255, 0.1);
		box-sizing: border-box;
		padding: 40px;
	}

	.login .tabbox {
		margin-bottom: 20px;
	}

	.login .tabbox .tab {
		width: 175px;
		height: 56px;
		font-size: 20px;
		border-bottom: #ffffff solid 3px;
		cursor: pointer;
	}

	.login .tabbox .tab-a {
		width: 175px;
		height: 56px;
		font-size: 20px;
		border-bottom: #006DFF solid 3px;
		cursor: pointer;
	}

	.login .input {
		width: 350px;
		height: 54px;
		background: #F5F5F5;
		border: 0;
		outline: none;
		margin-left: 20px;
		box-sizing: border-box;
		/* padding: 0 28px; */
		font-size: 14px;
	}

	.login .input2 {
		width: 350px;
		height: 50px;
		background: #F5F5F5;
		border: 0;
		outline: none;
		margin-bottom: 10px;
		box-sizing: border-box;
		padding: 0 28px;
		font-size: 14px;
	}

	.login .btn {
		width: 350px;
		height: 50px;
		background: #006DFF;
		font-size: 18px;
		color: #ffffff;
		margin-top: 24px;
		cursor: pointer;
	}

	.login .btn2 {
		width: 350px;
		height: 50px;
		background: #006DFF;
		font-size: 18px;
		color: #ffffff;
		margin-top: 16px;
		cursor: pointer;
	}

	.login .bom {
		width: 350px;
		margin-top: 22px;
	}

	.btns {
		height: 40px;
		line-height: 40px;
		margin-left: 20px;
		font-size: 14px;
		color: #409EFF;
		cursor: pointer;
	}
</style>